import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { graphql, Link } from 'gatsby'

// Components
import Pagina from '../components/Pagina'
import Layout from '../components/Layout/index'
// import Locator from '../components/Locator/Locator'
// Styles
import './styles/lojas.scss'

// Assets
// import iconSearch from '../images/icons/lojas/icon-search.svg'
// import iconMapActive from '../images/icons/lojas/icon-map-active.svg'
// import iconMapDisable from '../images/icons/lojas/icon-map-disable.svg'
// import iconListActive from '../images/icons/lojas/icon-list-active.svg'
// import iconListDisable from '../images/icons/lojas/icon-list-disable.svg'
// import iconMapActiveMobile from '../images/icons/lojas/icon-map-active-mobile.svg'
// import iconMapDisableMobile from '../images/icons/lojas/icon-map-disable-mobile.svg'
// import iconListActiveMobile from '../images/icons/lojas/icon-list-active-mobile.svg'
// import iconListDisableMobile from '../images/icons/lojas/icon-list-disable-mobile.svg'
import iconLojasLocation from '../images/icons/lojas/icon-lojas-location.svg'
import iconLojasOfertas from '../images/icons/lojas/icon-lojas-ofertas.svg'
import iconLojasTelefone from '../images/icons/lojas/icon-lojas-telefone.svg'
import iconLojasWhatsApp from '../images/icons/lojas/icon-lojas-whatsapp.svg'

const Pagination = forwardRef((props, ref) => {
  const [pages, setPages] = useState([1, 2, 3])

  useImperativeHandle(ref, () => ({
    goToFirstPage () {
      setPages([1, 2, 3])
    }
  }))

  const showMore = () => {
    if (pages[2] < Math.ceil(props.markets.length / 6)) {
      const newPages = pages.map(page => page + 1)
      setPages(newPages)
    }
  }

  const showLess = () => {
    if (pages[0] > 1) {
      const newPages = pages.map(page => page - 1)
      setPages(newPages)
    }
  }

  const next = () => {
    const page = props.pagination.active
    props.pagination.setActive(page + 6)
    if (page >= 12) {
      showMore()
    }
  }

  const prev = () => {
    props.pagination.setActive(props.pagination.active - 6)
    if (props.pagination.active >= 6) {
      showLess()
    }
  }

  const handleClick = (currentPagination, currentPage) => {
    props.pagination.setActive(currentPagination)
    if (pages.indexOf(currentPage) === 2) {
      showMore()
    } else if (pages.indexOf(currentPage) === 0) {
      showLess()
    }
  }
  return (
    <>
      <div className="d-flex justify-content-around justify-content-sm-center py-3">
        <button className="bg-transparent border-0 px-3 px-sm-4" aria-label="anterior" disabled={pages[0] === 1 && props.pagination.active === 0}
          onClick={() => prev()}>
          <i className={pages[0] === 1 && props.pagination.active === 0 ? 'arrow-gray arrow-left' : 'arrow-dark arrow-left'}></i>
        </button>
        <div className="row justify-content-center">
          {
            pages.map(page => {
              const currentPagination = (page - 1) * 6
              return (
                <div className="col d-flex justify-content-center custom-padding-lojas-1" key={page}>
                  <span key={page}><button onClick={() => handleClick(currentPagination, page)}
                    className={(currentPagination === props.pagination.active)
                      ? 'rounded px-3 py-2 text-white bg-blue border-0' : 'px-3 py-2 border-0 bg-transparent'}
                    style={{ outline: 'none' }}
                  >{page}</button></span>
                </div>
              )
            })
          }
        </div>
        <button className="bg-transparent border-0 px-3 px-sm-4" aria-label="próximo" disabled={pages[2] >= Math.ceil(props.markets.length / 6)}
          onClick={() => next()}>
          <i className={pages[2] >= Math.ceil(props.markets.length / 6) ? 'arrow-gray arrow-right' : 'arrow-dark arrow-right'}></i>
        </button>
      </div>
    </>
  )
})

Pagination.displayName = 'Pagination'

const Lojas = ({ data }) => {
  const { lojas } = data
  // eslint-disable-next-line no-unused-vars
  const [markets, setMarkets] = useState(lojas.edges.map(node => (node.node)))
  // const [page, setPage] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [currentMarket, setCurrentMarket] = useState(null)
  // const [searchName, setSearchName] = useState('Lojas Próximas')
  // const childRef = useRef()
  const paginationRef = useRef()
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/lojas/', name: 'Lojas' }
    ]
  }

  useEffect(() => {
    initMaps()
  }, [])

  const initMaps = () => {
    if (typeof window !== 'undefined') {
      if (!window.google) {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
        const insercao = document.getElementsByTagName('script')[0]
        insercao.parentNode.insertBefore(script, insercao)
        script.addEventListener('load', e => {
          console.log('Geocoder is now available!')
        })

        if (window.localStorage && currentMarket === null) {
          const temp = window.localStorage.getItem('currentMarket')
          setCurrentMarket(JSON.parse(temp))
        }
      }
    }
  }

  // const useCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const pos = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude
  //       }
  //       calcDistance(pos)
  //     })
  //   } else {
  //     console.error('Geolocation is not supported by this browser.')
  //   }
  // }

  // const handleSearch = (e) => {
  //   e.preventDefault()
  //   const geocoder = new window.google.maps.Geocoder()
  //   geocoder.geocode({
  //     address: e.target[0].value,
  //     region: 'br',
  //     componentRestrictions: {
  //       country: 'BR'
  //     }
  //   },
  //   (results, status) => {
  //     console.log('google results', { results: results, status: status })

  //     if (status === 'OK') {
  //       const pos = {
  //         lat: results[0].geometry.location.lat(),
  //         lng: results[0].geometry.location.lng()
  //       }
  //       calcDistance(pos)
  //       if (paginationRef.current) {
  //         paginationRef.current.goToFirstPage()
  //       }

  //       // const searchLabel = [...new Set(results[0].address_components.map(res => res.long_name))].reduce((a, b) => a + ', ' + b)
  //       setSearchName(`Resultados de Busca para "${results[0].formatted_address}"`)
  //     } else {
  //       console.log('FAIL')
  //     }
  //   })
  // }

  // const calcDistance = (p) => {
  //   console.log('>> CalcDistance')
  //   const myLat = p.lat
  //   const myLng = p.lng
  //   // eslint-disable-next-line no-unused-vars
  //   let mkts = lojas.edges.map(node => {
  //     const loja = node.node
  //     const LatLoja = loja.lat
  //     const LngLoja = loja.lng
  //     const radlat1 = (Math.PI * myLat) / 180
  //     const radlat2 = (Math.PI * LatLoja) / 180

  //     const theta = myLng - LngLoja
  //     const radtheta = (Math.PI * theta) / 180
  //     let dist =
  //       Math.sin(radlat1) * Math.sin(radlat2) +
  //       Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  //     dist = Math.acos(dist)
  //     dist = (dist * 180) / Math.PI
  //     dist = dist * 60 * 1.1515
  //     dist = dist * 1.609344
  //     loja.distance = parseFloat(dist.toFixed(1))
  //     return loja
  //   })

  //   mkts = mkts.sort((a, b) => (a.distance - b.distance))
  //   setMarkets(mkts)
  //   setActivePage(0)
  //   if (childRef.current) {
  //     childRef.current.currentMarket(mkts[0])
  //   }
  // }

  const handleMarketSelected = (market) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentMarket', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = market.nome
    }
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina} />
      <section className="container my-4">
        <h1 className="mb-4">Lojas</h1>
        <h2 className="mb-4">Encontre uma loja SPANI mais próxima de você</h2>
        {/* <div className="row d-flex justify-content-between">
          <div className="col col-lg-auto d-flex flex-column flex-lg-row justify-content-center aling-items-center">
            <form onSubmit={() => handleSearch(event)} className="d-flex">
              <div id="search-place" className="d-flex w-100 box-shadow">
                <input type="text" aria-labelledby="search-place" className="inputPesquisa border-0 w-100 custom-min-width font-size-1125" placeholder="Busca por CEP, endereço, bairro..." style={{ outline: 'none' }}/>
                <button className="bg-white border-0 inputButton" aria-label="search" style={{ outline: 'none' }}>
                  <img src={iconSearch} alt="lupa de procura" className="pr-3" />
                </button>
              </div>
            </form>
            <span className="d-inline py-2 px-3 text-center align-bottom">ou</span>
            <button className="bg-blue text-white border-0 buttonLocation custom-min-width font-size-1125" style={{ outline: 'none' }} onClick={() => useCurrentLocation()}>Usar minha localização atual</button>
          </div>
          <div className="col-auto d-none d-md-block">
            <a className={`${page ? 'icon-active' : 'icon-disable'} icon-lojas cursor-pointer mr-3`} onClick={() => setPage(true)}><img src={page ? iconListActive : iconListDisable } className="mr-2" alt="4 quadrados - icone de lista"/><span className="font-size-1125">LISTA</span></a>
            <a className={`${page ? 'icon-disable' : 'icon-active'} icon-lojas cursor-pointer`} onClick={() => setPage(false)}><img src={page ? iconMapDisable : iconMapActive } className="mr-2" alt="mapa - icone de mapa" /><span className="font-size-1125">MAPA</span></a>
          </div>
        </div> */}
      </section>
      {/* <hr/>
      <section className="container mb-2">
        <div className="row">
          <div className="col-6 col-md-12">
            <p className="font-size-1375 text-gray-2 custom-font-lojas">{searchName}</p>
          </div>
          <div className="col-6 d-flex justify-content-end d-block d-md-none">
            <div className="icon-lojas-mobile">
              <a className={`${page ? 'icon-active-mobile' : 'icon-disable-mobile'} icon-mobile icon-list-mobile cursor-pointer`} onClick={() => setPage(true)}><img alt="4 quadrados - icone de lista" src={page ? iconListActiveMobile : iconListDisableMobile } /></a>
              <a className={`${page ? 'icon-disable-mobile' : 'icon-active-mobile'} icon-mobile icon-map-mobile cursor-pointer`} onClick={() => setPage(false)}><img alt="mapa - icone de mapa" src={page ? iconMapDisableMobile : iconMapActiveMobile } /></a>
            </div>
          </div>
        </div>
      </section> */}
      {/* {page && */}
      <div className="container-fluid bg-gray pt-5">
        <div className="container">
          <div className="row">
            {
              markets.slice(activePage, activePage + 6).map((loja, index) => {
                return (
                  <div key={index} className="col-lg-6 col-12 p-0 p-md-3 mb-3">
                    <div className="card card-lojas border-0">
                      <div className="row">
                        <div className="col-xl-7 col-lg-6">
                          <h3 className="mb-2 font-openSans text-black">{loja.nome}</h3>
                          <p className="text-black font-size-1125" style={{ lineHeight: '24px' }}>
                            {loja.logradouro}<br/>
                            {loja.bairro} - {loja.estado} <br/>
                                  CEP {loja.cep} <br/>
                          </p>
                          <p className="text-gray-2 font-size-1 mt-2">Segunda a Sábado: {loja.diaUtilAbre} às {loja.diaUtilFecha}<br/> Domingo: {loja.domingoAbre} às {loja.domingoFecha}<br/> Feriados: {loja.feriadoAbre} às {loja.feriadoFecha}</p>
                        </div>
                        <div className="col-xl-5 col-lg-6 w-100">
                          <a href={`https://www.google.com/maps/dir/Minha+Localização/${loja.lat},${loja.lng}`} target="_blank" without rel="noreferrer"><button type="button" className="btn btn-white w-100 mt-4 mb-3 font-size-1125"><img src={iconLojasLocation} alt="icone de localização"/> Como chegar</button></a>
                          <Link to={`/lojas/${loja.slug}/`} className="mb-3 btn btn-yellow w-100 p-2 font-size-1125" onClick={() => handleMarketSelected(loja)}><img src={iconLojasOfertas} alt="balão com simbolo de porcentagem dentro" className="mr-1"/> Ofertas</Link>
                          <a href={`tel:${loja.telefone}`} target="_blank" without rel="noreferrer"><button type="button" className="mb-3 btn btn-blue w-100 font-size-1125"><img src={iconLojasTelefone} alt="balão de fala com três pontos dentro" className="mr-2"/>{loja.telefone}</button></a>
                          <a href={loja.shortLink} target="_blank" without rel="noreferrer"><button type="button" className="mb-3 btn btn-wpp w-100 font-size-1125"><img src={iconLojasWhatsApp} alt="balão de fala com três pontos dentro" className="mr-1"/>{loja.whatsApp}</button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <Pagination markets={markets} pagination={{ active: activePage, setActive: setActivePage }} ref={paginationRef} />
      </div>
      {/* <Locator markets={markets} currentMarket={currentMarket} ref={childRef} /> */}
    </Layout>
  )
}

export default Lojas

export const query = graphql`
query {
  pagina:contentfulPagina(path: { eq: "/lojas/" }) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
  }
  lojas: allContentfulLojas(sort: {fields: nome, order: ASC}) {
    edges {
      node {
        nome
        bairro
        cep
        diaUtilAbre
        diaUtilFecha
        domingoAbre
        domingoFecha
        estado
        feriadoAbre
        feriadoFecha
        id
        idTarifa
        lat
        lng
        logradouro
        municipio
        numero
        slug
        telefone
        whatsApp
        shortLink
      }
    }
  }
}`
